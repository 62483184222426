<template>
  <div class="body">
    <headers />

    <div role="main" class="main">

      <div id="index" class="custom-bg-color-grey-1">
        <div class="owl-carousel owl-carousel-light owl-carousel-light-init-fadeIn owl-theme manual dots-inside dots-horizontal-center show-dots-hover show-dots-xs nav-style-1 nav-arrows-thin nav-inside nav-inside-plus nav-dark nav-lg nav-font-size-lg show-nav-hover mb-0" data-plugin-options="{'autoplay': true, 'autoplayTimeout': 7000}" data-dynamic-height="['calc(100vh - 135px)','calc(100vh - 135px)','calc(100vh - 161px)','calc(100vh - 161px)','calc(100vh - 161px)']" style="height: calc(100vh - 135px);">
          <div class="owl-stage-outer">
            <div class="owl-stage">

              <!-- Carousel Slide 1 -->
              <div class="owl-item position-relative overflow-hidden">
                <div class="background-image-wrapper custom-bg-color-grey-1 position-absolute top-0 left-0 right-0 bottom-0" data-appear-animation="kenBurnsToLeft" data-appear-animation-duration="30s" data-plugin-options="{'minWindowWidth': 0}" data-carousel-onchange-show style="background-image: url(assets/landing/img/demos/kitakerja/slides/slide-1-bg.jpg); background-size: cover; background-position: 100% 100%;">
                </div>

                <img src="~@/assets/landing/img/demos/kitakerja/slides/slide-1-1-kitakerja.png" class="img-fluid position-absolute bottom-0 d-none d-lg-block custom-slider-el-1 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="600" alt="" />

                <div class="container h-100">
                  <div class="row h-100 align-items-center">
                    <div class="col">
                      <div class="text-end float-lg-start custom-slider-text-block">
                        <h2 class="font-weight-extra-bold mb-4 custom-slider-text-1 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="800"><em>#</em><span style="color: #2E72B9">KITA</span><span style="color: #EEC82A">KERJA</span></h2>
                        <h2 class="text-color-default font-weight-semi-bold mb-3 text-5 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1000">Ayo bergabung pada platform Kitakerja!</h2>
                        <a href="#courses" data-hash data-hash-offset="0" data-hash-offset-lg="70" class="btn btn-secondary font-weight-bold btn-px-5 btn-py-3 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1200">Masuk/Daftar</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Carousel Slide 2 -->
              <div class="owl-item position-relative overflow-hidden">
                <div class="background-image-wrapper custom-bg-color-grey-1 position-absolute top-0 left-0 right-0 bottom-0" data-appear-animation="kenBurnsToLeft" data-appear-animation-duration="30s" data-plugin-options="{'minWindowWidth': 0}" data-carousel-onchange-show style="background-image: url(assets/landing/img/demos/kitakerja/slides/slide-2-bg.jpg); background-size: cover; background-position: 100% 100%;">
                </div>

                <img src="~@/assets/landing/img/demos/kitakerja/slides/slide-2-1-kitakerja.png" class="img-fluid position-absolute bottom-0 d-none d-lg-block custom-slider-el-2 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="600" alt="" />

                <div class="container h-100">
                  <div class="row h-100 align-items-center">
                    <div class="col">
                      <div class="float-lg-end custom-slider-text-block text-end">
                        <h2 class="text-color-secondary font-weight-extra-bold mb-4 custom-slider-text-1 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="800"><em>#</em><span style="color: #2E72B9">KITA</span><span style="color: #EEC82A">KERJA</span></h2>
                        <h2 class="text-color-default font-weight-semi-bold mb-3 text-5 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1000">Ayo bergabung pada platform Kitakerja!</h2>
                        <a href="#courses" data-hash data-hash-offset="0" data-hash-offset-lg="70" class="btn btn-secondary font-weight-bold btn-px-5 btn-py-3 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1200">Masuk/Daftar</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="owl-nav">
            <button type="button" role="presentation" class="owl-prev"></button>
            <button type="button" role="presentation" class="owl-next"></button>
          </div>
        </div>
      </div>

      <div class="bg-light">
        <div class="container py-4">
          <div class="row pb-2">
            <div class="col-md-4 mb-4 mb-md-0">

              <div class="feature-box feature-box-steps">
                <div class="feature-box-step-number appear-animation" data-appear-animation="fadeInLeftShorterPlus" data-appear-animation-delay="250">
                  <em>1.</em>
                </div>
                <div class="feature-box-icon bg-color-quaternary feature-box-icon-extra-large appear-animation" data-appear-animation="fadeInLeftShorterPlus" data-appear-animation-delay="250">
                  <img class="icon-animated" width="100" height="46" src="~@/assets/landing/img/demos/kitakerja/icons/icon-web-search-engine.svg" alt="" data-icon data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}" />
                </div>
                <div class="feature-box-info appear-animation" data-appear-animation="fadeInLeftShorterPlus" data-appear-animation-delay="150">
                  <p class="mt-2 pt-1 mb-0 text-1 p-relative top-5 text-uppercase">Langkah 1</p>
                  <h4 class="mb-0 text-color-secondary">Cari Yayasan</h4>
                </div>
              </div>

            </div>
            <div class="col-md-4 mb-4 mb-md-0">

              <div class="feature-box feature-box-steps">
                <div class="feature-box-step-number appear-animation" data-appear-animation="fadeInLeftShorterPlus" data-appear-animation-delay="500">
                  <em>2.</em>
                </div>
                <div class="feature-box-icon bg-color-quaternary feature-box-icon-extra-large appear-animation" data-appear-animation="fadeInLeftShorterPlus" data-appear-animation-delay="500">
                  <img class="icon-animated" width="42" height="42" src="~@/assets/landing/img/demos/kitakerja/icons/icon-list.svg" alt="" data-icon data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary ms-2'}" />
                </div>
                <div class="feature-box-info appear-animation" data-appear-animation="fadeInLeftShorterPlus" data-appear-animation-delay="300">
                  <p class="mt-2 pt-1 mb-0 text-1 p-relative top-5 text-uppercase">Langkah 2</p>
                  <h4 class="mb-0 text-color-secondary">Bergabung Kegiatan / Proyek</h4>
                </div>
              </div>

            </div>
            <div class="col-md-4">

              <div class="feature-box feature-box-steps">
                <div class="feature-box-step-number appear-animation" data-appear-animation="fadeInLeftShorterPlus" data-appear-animation-delay="750">
                  <em>3.</em>
                </div>
                <div class="feature-box-icon bg-color-quaternary feature-box-icon-extra-large appear-animation" data-appear-animation="fadeInLeftShorterPlus" data-appear-animation-delay="750">
                  <img class="icon-animated" width="45" height="45" src="~@/assets/landing/img/demos/kitakerja/icons/icon-laptop.svg" alt="" data-icon data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary'}" />
                </div>
                <div class="feature-box-info appear-animation" data-appear-animation="fadeInLeftShorterPlus" data-appear-animation-delay="450">
                  <p class="mt-2 pt-1 mb-0 text-1 p-relative top-5 text-uppercase">Langkah 3</p>
                  <h4 class="mb-0 text-color-secondary">Nikmati dan Pelajari</h4>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div  id="aboutus" class="container position-relative my-4 mb-5">
          <div class="row align-items-xl-center">
            <div class="col-lg-6 ps-lg-4 ps-xl-5">
              <h2 class="custom-highlight-text-1 d-inline-block line-height-5 text-4 positive-ls-3 font-weight-medium text-color-primary mb-2 appear-animation" data-appear-animation="fadeInUpShorter"><span style="color: #212529">TENTANG</span><span style="color: #2E72B9">&nbsp;#KITA</span><span style="color: #EEC82A">KERJA</span></h2>
              <h3 class="text-9 text-lg-5 text-xl-9 line-height-3 text-transform-none font-weight-semibold mb-4 mb-lg-3 mb-xl-4 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="250">Di sini, anda dapat melihat beragam informasi dan terlibat dalam berbagai aksi dari Yayasan Plan International Indonesia.</h3>
              <p class="text-3-5 pb-1 mb-4 mb-lg-2 mb-xl-4 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="500">KitaKerja (https://kitakerja.id) merupakan platform yang diperuntukkan bagi kaum muda untuk peningkatan keterampilan mereka sehingga memudahkan kaum muda mendapatkan pekerjaan (employment) atau berwirausaha (entrepreneurship).</p>
            </div>
            <div class="col-md-10 col-lg-6 mb-5 mb-lg-0">
              <div class="row align-items-center">
                <div class="col-md-6">
                  <div class="card box-shadow-5 position-relative border-0 custom-box-shadow-1 custom-border-radius-1 mb-4 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="850">
                    <div class="custom-dots-rect-1" style="background-image: url(~@/assets/landing/img/demos/seo-2/dots-group.png);"></div>
                    <div class="card-body text-center pt-5">
                      <img src="~@/assets/landing/img/demos/seo-2/icons/target.svg" class="img-fluid mb-4 pb-2" width="80" height="80" alt="" data-icon data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary pb-2 mb-4'}" />
                      <h4 class="text-color-dark font-weight-semibold mb-2">Proyek</h4>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc viverra erat orci.</p>
                    </div>
                  </div>
                  <div class="card box-shadow-5 border-0 custom-box-shadow-1 custom-border-radius-1 mb-4">
                    <div class="card-body text-center pt-5">
                      <img src="~@/assets/landing/img/demos/seo-2/icons/search.svg" class="img-fluid mb-4 pb-2" width="80" height="80" alt="" data-icon data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary pb-2 mb-4'}" />
                      <h4 class="text-color-dark font-weight-semibold mb-2">Yayasan</h4>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc viverra erat orci.</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card box-shadow-5 position-relative border-0 custom-box-shadow-1 custom-border-radius-1 mb-4 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1000">
                    <div class="custom-dots-rect-2" style="background-image: url(~@/assets/landing/img/demos/seo-2/dots-group-2.png);"></div>
                    <div class="card-body text-center pt-5">
                      <img src="~@/assets/landing/img/demos/seo-2/icons/teaching.svg" class="img-fluid mb-4 pb-2" width="70" height="70" alt="" data-icon data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-primary pb-2 mb-4'}" />
                      <h4 class="text-color-dark font-weight-semibold mb-2">Kegiatan</h4>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc viverra erat orci.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section id="proyekkita" class="section section-height-4 section-with-shape-divider bg-color-grey-scale-1 border-0 pb-5 m-0">
        <div class="container position-relative z-index-1 mt-3 mb-5">
          <div class="custom-circle custom-circle-small custom-circle-pos-14">
            <div class="custom-bg-color-grey-2 rounded-circle w-100 h-100" data-plugin-float-element data-plugin-options="{'startPos': 'top', 'speed': 1, 'horizontal': true, 'transition': true, 'transitionDuration': 1000}"></div>
          </div>
          <div class="custom-circle custom-circle-extra-small custom-circle-pos-15">
            <div class="custom-bg-color-grey-2 rounded-circle w-100 h-100" data-plugin-float-element data-plugin-options="{'startPos': 'top', 'speed': 1, 'horizontal': true, 'transition': true, 'transitionDuration': 1000}"></div>
          </div>
          <div class="custom-circle custom-circle-medium custom-circle-pos-16">
            <div class="custom-bg-color-grey-1 rounded-circle w-100 h-100" data-plugin-float-element data-plugin-options="{'startPos': 'top', 'speed': 1, 'horizontal': true, 'transition': true, 'transitionDuration': 1000}"></div>
          </div>
          <div class="row pb-2 mb-4">
            <div class="col">
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 text-center text-lg-start">
              <img src="~@/assets/landing/img/kitakerja/concept-2.png" class="img-fluid appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="600" alt="" />
            </div>
            <div class="col-lg-6 mb-5 mb-lg-0 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="450">
              <h2 class="text-color-dark font-weight-semibold text-6 line-height-3 mb-0 pe-5 me-5">Layanan Yang Tersedia</h2>
              <span class="d-block mb-3">PROYEK KITA</span>
              <p class="lead pe-5 mb-4 pb-2">Sebagai suatu platform untuk employment dan entrepreneurship, maka aplikasi ini menyediakan berbagai layanan berikut ini:</p>
              <div class="feature-box">
                <div class="feature-box-icon custom-feature-box-icon-size-1 bg-color-primary top-0">
                  <i class="fas fa-puzzle-piece position-relative left-1"></i>
                </div>
                <div class="feature-box-info mb-4 pb-3">
                  <h4 class="font-weight-bold line-height-3 custom-font-size-1 mb-1"><span style="color: #2E72B9">Mentoring</span></h4>
                  <p class="mb-0">Consectetur adipiscing elit. Sed imperdiet libero id nisi euismod sed porta est consectetur.</p>
                  <a href="https://kitakerja.id/" class="text-color-primary font-weight-bold">Bergabung sekarang ></a>
                </div>
              </div>
              <div class="feature-box">
                <div class="feature-box-icon custom-feature-box-icon-size-1 bg-color-primary top-0">
                  <i class="fas fa-map-signs position-relative top-2 right-1"></i>
                </div>
                <div class="feature-box-info">
                  <h4 class="font-weight-bold line-height-3 custom-font-size-1 mb-1"><span style="color: #2E72B9">YES!</span><span style="color: #EEC82A">&nbsp;Academy</span></h4>
                  <p class="mb-0">Consectetur adipiscing elit. Sed imperdiet libero id nisi euismod sed porta est consectetur.</p>
                  <a href="https://academy.yesdigital.org/?lang=id" class="text-color-primary font-weight-bold">Bergabung sekarang ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="shape-divider" style="height: 123px;">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1920 123" preserveAspectRatio="xMinYMin">
            <polygon fill="#F3F3F3" points="0,90 221,60 563,88 931,35 1408,93 1920,41 1920,-1 0,-1 "/>
            <polygon fill="#FFFFFF" points="0,75 219,44 563,72 930,19 1408,77 1920,25 1920,-1 0,-1 "/>
          </svg>
        </div>
      </section>

      <div class="bg-light">
        <div class="container mt-4">
          <div class="row justify-content-center">
            <div class="col-lg-11 col-xl-10 text-center">
              <h2 class="custom-highlight-text-1 d-inline-block line-height-5 text-4 positive-ls-3 font-weight-medium text-color-primary mb-2 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="250">KEGIATAN KITAKERJA</h2>
              <h3 class="text-9 line-height-3 text-transform-none font-weight-semibold mb-3 pb-1 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="500">Rekomendasi Kegiatan</h3>
              <p class="text-3-5 pb-3 mb-4 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="750">Berikut ini adalah kegiatan yang kami lakukan untuk pengembangan keterampilan kaum muda! Kaum muda silahkan bergabung dengan kegiatan ini. Harap diperhatikan, kegiatan ini bersifat GRATIS sehingga tidak akan ada pemungutan biaya.</p>
            </div>
          </div>
          <div class="row justify-content-center pb-2 mb-4">
            <div class="col-md-7 col-lg-4 mb-4 mb-lg-0">
              <div class="card border-0 box-shadow-5 custom-box-shadow-1 custom-border-radius-1 mb-4 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="400" data-plugin-options="{'accY': -100}">
                <div class="custom-dots-rect-3" style="background-image: url(~@/assets/landing/img/demos/seo-2/dots-group-3.png);"></div>
                <div class="card-body text-center p-5">
                  <img src="~@/assets/landing/img/kitakerja/kegiatan-1.jpg" class="img-fluid mb-4 mt-3 pb-3" width="175" alt="" />
                  <h4 class="text-color-dark font-weight-semibold mb-3">Junior Web Programmer</h4>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc viverra erat orci, ac auctor.</p>
                  <a href="#" class="text-color-primary font-weight-bold">SELENGKAPNYA +</a>
                </div>
              </div>
              <div class="card border-0 box-shadow-5 custom-box-shadow-1 custom-border-radius-1 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="400" data-plugin-options="{'accY': -100}">
                <div class="card-body text-center p-5">
                  <img src="~@/assets/landing/img/kitakerja/digital-marketing.jpg" class="img-fluid mb-4 mt-3 pb-3" width="175" alt="" />
                  <h4 class="text-color-dark font-weight-semibold mb-3">Digital Marketing</h4>
                  <p>Kegiatan pembelajaran mandiri Digital Marketing bagi peserta program beasiswa Work in Tech (WIT).</p>
                  <a href="#" class="text-color-primary font-weight-bold">SELENGKAPNYA +</a>
                </div>
              </div>
            </div>
            <div class="col-md-7 col-lg-4 pt-lg-4 mt-lg-5 mb-4 mb-lg-0">
              <div class="card border-0 box-shadow-5 custom-box-shadow-1 custom-border-radius-1 mb-4 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="250">
                <div class="card-body text-center p-5">
                  <img src="~@/assets/landing/img/kitakerja/wired4work.png" class="img-fluid mb-4 mt-3 pb-3" width="200" alt="" />
                  <h4 class="text-color-dark font-weight-semibold mb-3">Soft Skills W4W 2.0</h4>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc viverra erat orci, ac auctor.</p>
                  <a href="#" class="text-color-primary font-weight-bold">SELENGKAPNYA +</a>
                </div>
              </div>
              <div class="card border-0 box-shadow-5 custom-box-shadow-1 custom-border-radius-1 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="250">
                <div class="card-body text-center p-5">
                  <img src="~@/assets/landing/img/kitakerja/wired4work.png" class="img-fluid mb-4 mt-3 pb-3" width="200" alt="" />
                  <h4 class="text-color-dark font-weight-semibold mb-3">Green Skills W4W 2.0</h4>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc viverra erat orci, ac auctor.</p>
                  <a href="#" class="text-color-primary font-weight-bold">SELENGKAPNYA +</a>
                </div>
              </div>
            </div>
            <div class="col-md-7 col-lg-4">
              <div class="card border-0 box-shadow-5 custom-box-shadow-1 custom-border-radius-1 mb-4 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="500" data-plugin-options="{'accY': -100}">
                <div class="card-body text-center p-5">
                  <img src="~@/assets/landing/img/kitakerja/wired4work.png" class="img-fluid mb-4 mt-3 pb-3" width="200" alt="" />
                  <h4 class="text-color-dark font-weight-semibold mb-3">Technical Skills W4W 2.0</h4>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc viverra erat orci, ac auctor.</p>
                  <a href="#" class="text-color-primary font-weight-bold">SELENGKAPNYA +</a>
                </div>
              </div>
              <div class="card border-0 box-shadow-5 custom-box-shadow-1 custom-border-radius-1 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="500" data-plugin-options="{'accY': -100}">
                <div class="card-body text-center p-5">
                  <img src="~@/assets/landing/img/kitakerja/default.png" class="img-fluid mb-4 mt-3 pb-3" width="200" alt="" />
                  <h4 class="text-color-dark font-weight-semibold mb-3">Live Mentoring Session E</h4>
                  <p>Mentoring Technical skill session ke-9 (IT Support) Jawa Timur kelas E</p>
                  <a href="#" class="text-color-primary font-weight-bold">SELENGKAPNYA +</a>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col text-center">
              <div class="overflow-hidden">
                <a href="#" class="btn btn-secondary btn-outline btn-rounded font-weight-bold px-5 py-3 text-3 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="250">BERGABUNG SEKARANG</a>
              </div>
            </div>
          </div>
          <div class="custom-circle custom-circle-big custom-circle-pos-20 appear-animation" data-appear-animation="expandInWithBlur" data-appear-animation-delay="200" data-appear-animation-duration="2s">
            <div class="bg-color-secondary rounded-circle w-100 h-100" data-plugin-float-element data-plugin-options="{'startPos': 'bottom', 'speed': 1, 'transition': true, 'transitionDuration': 2000}"></div>
          </div>
          <div class="custom-circle custom-circle-small custom-circle-pos-21 appear-animation" data-appear-animation="expandInWithBlur" data-appear-animation-delay="700" data-appear-animation-duration="2s">
            <div class="custom-bg-color-grey-2 rounded-circle w-100 h-100" data-plugin-float-element data-plugin-options="{'startPos': 'bottom', 'speed': 0.5, 'transition': true, 'transitionDuration': 1000}"></div>
          </div>
          <div class="custom-circle custom-circle-medium custom-circle-pos-22 appear-animation" data-appear-animation="expandInWithBlur" data-appear-animation-delay="1200" data-appear-animation-duration="2s">
            <div class="custom-bg-color-grey-1 rounded-circle w-100 h-100" data-plugin-float-element data-plugin-options="{'startPos': 'bottom', 'speed': 0.5, 'transition': true, 'transitionDuration': 3000}"></div>
          </div>
          <div class="custom-circle custom-circle-extra-small custom-circle-pos-23 appear-animation" data-appear-animation="expandInWithBlur" data-appear-animation-delay="1700" data-appear-animation-duration="2s">
            <div class="custom-bg-color-grey-2 rounded-circle w-100 h-100" data-plugin-float-element data-plugin-options="{'startPos': 'bottom', 'speed': 0.5, 'transition': true, 'transitionDuration': 1500}"></div>
          </div>
        </div>
      </div>
      <section id="kontak" class="section section-height-3 border-0 m-0 lazyload" data-bg-src="~@/assets/landing/img/demos/business-consulting-3/backgrounds/background-3.jpg">
        <div class="container pb-2 mb-4">
          <div class="row justify-content-center">
            <div class="col-md-10 col-lg-6 mb-5 mb-lg-0">
              <div class="overflow-hidden mb-1">
                <h2 class="text-color-dark font-weight-semibold text-6 line-height-3 mb-0 pe-5 me-5 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="1200">Ayo bergabung pada platform Kitakerja</h2>
              </div>
              <div class="overflow-hidden mb-3">
                <span class="d-block mb-0 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="1400">Hubungi Kami</span>
              </div>
              <p class="lead pe-5 mb-4 pb-2 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1600">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed imperdiet libero id nisi euismod, sed porta est consectetur.</p>
              <div class="feature-box align-items-center mb-4 pb-1 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1800">
                <div class="feature-box-icon custom-feature-box-icon-size-1 bg-color-primary top-0">
                  <i class="icon-globe icons position-relative"></i>
                </div>
                <div class="feature-box-info">
                  <h4 class="font-weight-bold line-height-1 custom-font-size-1 mb-1">Alamat</h4>
                  <p class="mb-0">Jl. Raya Padjajaran No. 7</p>
                </div>
              </div>
              <div class="feature-box align-items-center mb-4 pb-1 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="2000">
                <div class="feature-box-icon custom-feature-box-icon-size-1 bg-color-quaternary top-0">
                  <i class="icon-call-in icons position-relative top-2 right-1"></i>
                </div>
                <div class="feature-box-info">
                  <h4 class="font-weight-bold line-height-1 custom-font-size-1 mb-1">Telp</h4>
                  <p class="mb-0"><a href="tel:+1234567890" class="custom-text-color-grey-1">(021) 123-456</a> / <a href="tel:021123456" class="custom-text-color-grey-1">(021) 123-456</a></p>
                </div>
              </div>
              <div class="feature-box align-items-center appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="2200">
                <div class="feature-box-icon custom-feature-box-icon-size-1 bg-color-primary top-0">
                  <i class="icon-envelope icons position-relative top-1"></i>
                </div>
                <div class="feature-box-info">
                  <h4 class="font-weight-bold line-height-1 custom-font-size-1 mb-1">E-mail</h4>
                  <p class="mb-0"><a href="mailto:maile@example.com" class="custom-text-color-grey-1">cs@kitakerja.id</a></p>
                </div>
              </div>
            </div>
            <div class="col-md-10 col-lg-6 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="2000">
              <div class="card border-0 custom-border-radius-1 box-shadow-1 p-2">
                <div class="card-body p-4 z-index-1">
                  <h4 class="text-color-dark font-weight-semibold text-5 line-height-3 ls-0 mb-1 mt-2 pe-5 me-5">Kirimi Kami Pesan</h4>
                  <p class="pb-2 mb-4">Pellentesque ultricies nibh gravida, accumsan libero luctus. </p>

                  <form class="contact-form custom-form-style-1" action="php/contact-form.php" method="POST">
                    <div class="contact-form-success alert alert-success d-none mt-4">
                      <strong>Success!</strong> Your message has been sent to us.
                    </div>

                    <div class="contact-form-error alert alert-danger d-none mt-4">
                      <strong>Error!</strong> There was an error sending your message.
                      <span class="mail-error-message text-1 d-block"></span>
                    </div>

                    <div class="row">
                      <div class="form-group col mb-3">
                        <input type="text" value="" data-msg-required="Silahkan masukan nama Anda." maxlength="100" class="form-control custom-bg-color-light-1 border-0" name="name" id="name" placeholder="Your Name" required>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col mb-3">
                        <input type="email" value="" data-msg-required="Silakan masukkan alamat email Anda." data-msg-email="Please enter a valid email address." maxlength="100" class="form-control custom-bg-color-light-1 border-0" name="email" id="email" placeholder="E-mail Address" required>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col mb-3">
                        <textarea maxlength="5000" data-msg-required="Silakan masukkan pesan Anda." rows="6" class="form-control custom-bg-color-light-1 border-0" name="message" id="message" placeholder="Your Message" required></textarea>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col mb-3">
                        <input type="submit" value="Kirim Sekarang" class="btn btn-secondary btn-outline btn-rounded font-weight-bold px-5 py-3 text-3 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="250" data-loading-text="Loading...">
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
    <footers />
  </div>
  <!-- <div class="misc-wrapper">
    <b-link class="brand-logo">
      <vuexy-logo />
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Page Not Found 🕵🏻‍♀️
        </h2>
        <p class="mb-2">
          Oops! 😖 The requested URL was not found on this server.
        </p>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{path:'/'}"
        >
          Back to home
        </b-button>

        <b-img
          fluid
          :src="imgUrl"
          alt="Error page"
        />
      </div>
    </div>
  </div> -->
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue'
import Headers from '@/views/landingpage/Headers.vue'
import Footers from '@/views/landingpage/Footers.vue'
import store from '@/store/index'
import $ from 'jquery'

import '@/assets/landing/vendor/jquery.appear/jquery.appear.min'
import '@/assets/landing/vendor/jquery.easing/jquery.easing.min'
import '@/assets/landing/vendor/jquery.cookie/jquery.cookie.min'
import '@/assets/landing/vendor/bootstrap/js/bootstrap.bundle.min'
import '@/assets/landing/vendor/jquery.validation/jquery.validate.min'
import '@/assets/landing/vendor/jquery.easy-pie-chart/jquery.easypiechart.min'
// import '@/assets/landing/vendor/jquery.gmap/jquery.gmap.min'
import '@/assets/landing/vendor/lazysizes/lazysizes.min'
import '@/assets/landing/vendor/isotope/jquery.isotope.min'
// import '@/assets/landing/vendor/owl.carousel/owl.carousel.min'
import '@/assets/landing/vendor/magnific-popup/jquery.magnific-popup.min'
import '@/assets/landing/vendor/vide/jquery.vide.min'
import '@/assets/landing/vendor/vivus/vivus.min'
import '@/assets/landing/vendor/jquery.countdown/jquery.countdown.min'

export default {
  components: {
    Headers,
    Footers,
    // BLink,
    // BButton,
    // BImg,
  },
  data() {
    return {
      // downImg: require('@/assets/images/pages/error.svg'),
    }
  },
  mounted() {
    // alert($('#footer h4').html())
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
}
</script>
